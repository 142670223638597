import React, { useEffect, useState } from 'react';
import Loader from '../Loader';

import './style.scss';

export default function AppForm({
    formCategory = 'Consultation',
    className = '',
    formName = 'Consultation Form',
    children,
    explainerText,
    submitBtnText = 'Get In Contact',
}) {
    const [submittingForm, setSubmittingForm] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [submitSuccessful, setSubmissionSuccessful] = useState(false);
    const [relativeUrl, setRelativeUrl] = useState('/');

    useEffect(() => {
        let relativeURL = window.location.pathname + window.location.search;

        if (!relativeURL.endsWith('/')) {
            relativeURL += '/';
        }
        setRelativeUrl(relativeURL);
    }, []);

    const submitForm = () => {
        setSubmittingForm(true);
        setSubmissionError(false);

        const formName = document.getElementById('form-id-hook');

        fetch(relativeUrl, {
            method: 'POST',
            body: new FormData(formName),
        }).then(res => {
            if (res.status === 200) {
                setSubmissionSuccessful(true);
                setSubmittingForm(false);
            } else {
                setSubmittingForm(false);
                setSubmissionError(true);
            }
        });
    };

    return (
        <form
            action={relativeUrl + '#thanks'}
            className={'app-form pink-form ' + className}
            data-category={formCategory}
            encType="multipart/form-data"
            id="form-id-hook"
            method="POST"
            name={formName}
            netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={e => {
                e.preventDefault();
                submitForm();
            }}
        >
            {!submitSuccessful && explainerText && (
                <div
                    className="explainer-text"
                    dangerouslySetInnerHTML={{ __html: explainerText }}
                ></div>
            )}
            {submittingForm && <Loader />}
            {submissionError && (
                <p id="error-msg" className="show-error">
                    Looks like there was a problem submitting your form. <br />
                    Please ensure ALL form fields are filled out properly and
                    try again.
                </p>
            )}
            {submitSuccessful && (
                <div className="thank-you" id="thanks">
                    <h5>
                        Your form has been successfully submitted! We'll be in
                        touch shortly.
                    </h5>
                </div>
            )}
            {!submittingForm && !submitSuccessful ? (
                <>
                    <input type="hidden" name="form-name" value={formName} />
                    <input type="hidden" name="bot-field" id="bot" />
                    {children}
                    <div className="centered-text">
                        <button type="submit" className="submit-btn">
                            {submitBtnText}
                        </button>
                    </div>
                </>
            ) : (
                ''
            )}
        </form>
    );
}
