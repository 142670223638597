import React, { useEffect, useState } from "react"
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import "./style.scss"

const DropdownMenu = ({imageUrl , position}) => {
  var mediumForegroundCoordinates;
  var smallForegroundCoordinates;
  if(position === 'left') {
    mediumForegroundCoordinates = [300, 80];
    smallForegroundCoordinates  = [300, 20];
  }
  else {
    mediumForegroundCoordinates = [300, 20];
    smallForegroundCoordinates  = [500, 50];

  }

  return (
    <ParallaxProvider>
    <div className={ `career-parallax ` + position }>
      <img
        className="background-hex"
        src={require('../../svgs/careers/background-hex.svg')}
        alt="five star review"
      />
      <Parallax
          y={[80, 40]}
          tagOuter="div"
          className="layer-3 layered-images"
      >
        <div className="transparent-hex large" />
      </Parallax>
      <Parallax
          y={[100, 50]}
          tagOuter="div"
          className="layer-3 layered-images"
      >
        <img
          className="main-image"
          src={imageUrl}
          alt="five star review"
        />
      </Parallax>
      <Parallax
          y={mediumForegroundCoordinates}
          tagOuter="div"
          className="layer-3 layered-images"
      >
        <div className="transparent-hex medium" />

      </Parallax>
      <Parallax
          y={smallForegroundCoordinates}
          tagOuter="div"
          className="layer-3 layered-images"
      >
        <div className="transparent-hex small" />

      </Parallax>
    </div>
    </ParallaxProvider>

  )
}

export default DropdownMenu
